import React from "react";

export const WPTechLogo = () => {
  return (
    <svg
      width="276"
      height="74"
      viewBox="0 0 138 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.9766 30.9778C19.2779 29.3574 20.0024 26.2187 18.5679 23.7341L8.32912 6C6.02774 7.6204 5.30327 10.7591 6.73778 13.2437L16.9766 30.9778Z"
        fill="#404040"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.2813 30.9778C30.5827 29.3574 31.3072 26.2187 29.8727 23.7341L19.6339 6C17.3325 7.6204 16.608 10.7591 18.0425 13.2437L28.2813 30.9778Z"
        fill="#404040"
      />
      <path
        d="M31.3047 10.2976C31.3047 8.08847 33.0956 6.29761 35.3047 6.29761C37.5139 6.29761 39.3047 8.08847 39.3047 10.2976C39.3047 12.5067 37.5139 14.2976 35.3047 14.2976C33.0956 14.2976 31.3047 12.5067 31.3047 10.2976Z"
        fill="#404040"
      />
      <path
        d="M58.1268 24.7889H55.7507L51.3047 12.1889H54.3647L56.9927 20.5589L59.4948 12.1889H61.7627L64.2647 20.5589L66.8927 12.1889H69.9528L65.5067 24.7889H63.1307L60.6287 16.8689L58.1268 24.7889Z"
        fill="#404040"
      />
      <path
        d="M71.1277 12.1889H76.4917C79.1197 12.1889 81.0097 14.0969 81.0097 16.7429C81.0097 19.3889 79.1017 21.2969 76.4917 21.2969H74.0437V24.7889H71.1277V12.1889ZM74.0437 18.9209H76.0417C77.2117 18.9209 78.0577 18.0029 78.0577 16.7429C78.0577 15.4829 77.2117 14.5649 76.0417 14.5649H74.0437V18.9209Z"
        fill="#404040"
      />
      <path
        d="M91.9746 24.7889H89.0766V14.7809H85.6206V12.1889H95.4486V14.7809H91.9746V24.7889Z"
        fill="#404040"
      />
      <path
        d="M104.461 24.7889H96.7566V12.1889H104.461V14.5289H99.6726V17.3909H104.461V19.6769H99.6726V22.4489H104.461V24.7889Z"
        fill="#404040"
      />
      <path
        d="M112.789 25.1489C108.649 25.1489 106.021 22.3589 106.021 18.4889C106.021 14.6189 108.649 11.8289 112.789 11.8289C116.479 11.8289 118.873 13.7909 119.215 16.7609H116.119C115.795 15.3389 114.607 14.4389 112.807 14.4389C110.431 14.4389 109.063 16.0229 109.063 18.4889C109.063 20.9549 110.431 22.5389 112.807 22.5389C114.607 22.5389 115.795 21.6389 116.119 20.2169H119.215C118.873 23.1869 116.479 25.1489 112.789 25.1489Z"
        fill="#404040"
      />
      <path
        d="M123.842 24.7889H120.926V12.1889H123.842V17.2829H128.199V12.1889H131.115V24.7889H128.199V19.7849H123.842V24.7889Z"
        fill="#404040"
      />
    </svg>
  );
};

export const TwicemeLogo = () => {
  return (
    <svg
      width="141"
      height="128"
      viewBox="0 0 141 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84.9281 93.1545L81.9216 87.9792L72.5251 93.4431V82.4316H66.5401V93.4105L57.204 87.9792L54.1929 93.1545L63.608 98.6277L54.4396 103.957L57.4507 109.132L66.5401 103.849V114.219H72.5251V103.812L81.675 109.132L84.6815 103.957L75.513 98.6277L84.9281 93.1545Z"
        fill="#231F20"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M104.842 91.309L92.4569 113.777V113.772L92.3825 113.912C87.8541 122.182 78.923 127.316 69.7686 127.316C60.6141 127.316 52.0693 122.387 47.4665 114.457C47.2524 114.093 47.0523 113.726 46.8614 113.354L0 27.5192L1.88023 25.9462C20.4963 9.22429 44.3436 0 69.6755 0C95.0074 0 119.269 9.37786 137.988 26.4116L139.737 28.0034L128.527 48.3391L104.842 91.309ZM132.202 29.2785L123.29 45.4477L99.6048 88.418L87.2073 110.91L87.1375 111.041L87.1328 111.036C83.6515 117.384 76.9963 121.331 69.7639 121.331C62.5315 121.331 56.1881 117.552 52.6464 111.469L52.1065 110.482L7.58141 28.9109C24.8758 14.111 46.8381 5.98508 69.6802 5.98508C92.5221 5.98508 114.838 14.2413 132.202 29.2785Z"
        fill="#231F20"
      />
      <path
        d="M31.4451 46.3026L34.3678 51.5523C44.7091 44.2734 56.8654 40.4245 69.529 40.4245C82.1927 40.4245 94.4467 44.306 104.816 51.65L107.739 46.4004C96.4666 38.5816 83.277 34.4395 69.5244 34.4395C55.7717 34.4395 42.6845 38.5443 31.4404 46.3072L31.4451 46.3026Z"
        fill="#231F20"
      />
      <path
        d="M69.5312 23.7644C85.0989 23.7644 100.108 28.3485 112.967 37.0097L115.885 31.7693C102.133 22.6195 86.1228 17.7793 69.5312 17.7793C52.9396 17.7793 37.0321 22.5916 23.312 31.6809L26.23 36.9213C39.0565 28.316 54.0286 23.7644 69.5359 23.7644H69.5312Z"
        fill="#231F20"
      />
      <path
        d="M39.6772 61.0488L42.6139 66.3218C50.4095 60.36 59.7128 57.2092 69.5375 57.2092C79.3622 57.2092 88.7679 60.3973 96.5867 66.4242L99.5235 61.1512C90.7925 54.6588 80.4512 51.2241 69.5375 51.2241C58.6238 51.2241 48.3802 54.6263 39.6772 61.0535V61.0488Z"
        fill="#231F20"
      />
      <path
        d="M69.5354 68.0093C61.6003 68.0093 53.9863 70.797 47.9453 75.8838L50.9239 81.2313C56.0201 76.568 62.6288 73.9944 69.54 73.9944C76.4513 73.9944 83.1624 76.6053 88.2725 81.3431L91.2511 75.9909C85.1868 70.8343 77.5311 68.0093 69.5354 68.0093Z"
        fill="#231F20"
      />
    </svg>
  );
};

export const ProventoLogo = () => {
  return (
    <svg
      width="482"
      height="128"
      viewBox="0 0 482 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-3/4 md:w-[482px] h-auto"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M114.636 63.6533C114.636 89.8222 95.0408 111.406 69.7487 114.478C66.3348 114.893 63.5335 117.641 63.5335 121.08C63.5335 124.519 66.3297 127.339 69.7526 127.006C101.922 123.874 127.067 96.7058 127.067 63.6533C127.067 28.4986 98.622 0 63.5335 0C52.0763 0 41.3275 3.03841 32.0459 8.35484C28.3914 10.4481 24.9643 12.8946 21.8111 15.6478C18.3297 18.6875 15.182 22.1012 12.4305 25.8263C4.61902 36.4016 0 49.487 0 63.6533V110.01C0 119.563 7.72959 127.307 17.2645 127.307C23.5331 127.307 29.0213 123.96 32.0459 118.952C33.6219 116.342 34.529 113.282 34.529 110.01V93.2996C42.0029 100.64 52.2406 105.166 63.5335 105.166C86.4173 105.166 104.968 86.5804 104.968 63.6533C104.968 40.7263 86.4173 22.1403 63.5335 22.1403C52.2406 22.1403 42.0029 26.6665 34.529 34.0071C26.858 41.5414 22.0986 52.0405 22.0986 63.6533V110.01C22.0986 110.589 21.9971 111.144 21.8111 111.659C21.1377 113.522 19.3563 114.853 17.2645 114.853C14.5947 114.853 12.4305 112.684 12.4305 110.01V63.6533C12.4305 52.4598 16.0158 42.1053 22.0986 33.6795C25.5175 28.9438 29.7253 24.8174 34.529 21.4938C42.769 15.7927 52.762 12.4539 63.5335 12.4539C91.7569 12.4539 114.636 35.3767 114.636 63.6533ZM63.5335 92.7125C79.5521 92.7125 92.5379 79.7023 92.5379 63.6533C92.5379 47.6044 79.5521 34.5942 63.5335 34.5942C47.5148 34.5942 34.529 47.6044 34.529 63.6533C34.529 79.7023 47.5148 92.7125 63.5335 92.7125Z"
        fill="#FF8000"
      />
      <path
        d="M201.814 52.2381C201.814 55.3377 201.068 58.2437 199.577 60.9558C198.14 63.668 195.848 65.8544 192.699 67.5149C189.605 69.1754 185.682 70.0057 180.931 70.0057H171.235V92.2567H159.634V34.3045H180.931C185.406 34.3045 189.218 35.0794 192.367 36.6292C195.516 38.179 197.864 40.31 199.411 43.0222C201.013 45.7344 201.814 48.8063 201.814 52.2381ZM180.434 60.6237C183.638 60.6237 186.014 59.9042 187.561 58.4651C189.108 56.9706 189.881 54.8949 189.881 52.2381C189.881 46.5923 186.732 43.7694 180.434 43.7694H171.235V60.6237H180.434Z"
        fill="#162521"
      />
      <path
        d="M215.024 53.4005C216.515 50.965 218.449 49.0554 220.824 47.6717C223.255 46.2879 226.018 45.596 229.111 45.596V57.8008H226.045C222.399 57.8008 219.637 58.6588 217.758 60.3747C215.935 62.0905 215.024 65.0795 215.024 69.3415V92.2567H203.422V46.2602H215.024V53.4005Z"
        fill="#162521"
      />
      <path
        d="M250.682 93.0039C246.262 93.0039 242.285 92.0353 238.749 90.098C235.213 88.1054 232.423 85.3102 230.379 81.7124C228.39 78.1146 227.396 73.9633 227.396 69.2585C227.396 64.5536 228.418 60.4023 230.462 56.8045C232.561 53.2067 235.406 50.4392 238.998 48.5019C242.589 46.5093 246.594 45.513 251.014 45.513C255.433 45.513 259.439 46.5093 263.03 48.5019C266.621 50.4392 269.438 53.2067 271.482 56.8045C273.582 60.4023 274.631 64.5536 274.631 69.2585C274.631 73.9633 273.554 78.1146 271.4 81.7124C269.3 85.3102 266.427 88.1054 262.781 90.098C259.19 92.0353 255.157 93.0039 250.682 93.0039ZM250.682 82.8747C252.782 82.8747 254.743 82.3766 256.566 81.3803C258.444 80.3286 259.936 78.7788 261.041 76.7308C262.146 74.6828 262.698 72.192 262.698 69.2585C262.698 64.8857 261.538 61.537 259.218 59.2123C256.953 56.8322 254.163 55.6422 250.848 55.6422C247.533 55.6422 244.743 56.8322 242.478 59.2123C240.268 61.537 239.163 64.8857 239.163 69.2585C239.163 73.6312 240.241 77.0076 242.395 79.3876C244.605 81.7124 247.367 82.8747 250.682 82.8747Z"
        fill="#162521"
      />
      <path
        d="M295.655 81.5463L307.257 46.2602H319.605L302.616 92.2567H288.528L271.623 46.2602H284.053L295.655 81.5463Z"
        fill="#162521"
      />
      <path
        d="M362.169 68.2621C362.169 69.9227 362.059 71.4171 361.838 72.7455H328.275C328.552 76.0666 329.712 78.6681 331.756 80.55C333.8 82.4319 336.314 83.3729 339.297 83.3729C343.606 83.3729 346.672 81.5186 348.496 77.8101H361.009C359.683 82.2382 357.142 85.8913 353.385 88.7696C349.628 91.5925 345.015 93.0039 339.546 93.0039C335.126 93.0039 331.148 92.0353 327.612 90.098C324.132 88.1054 321.397 85.3102 319.408 81.7124C317.475 78.1146 316.508 73.9633 316.508 69.2585C316.508 64.4983 317.475 60.3193 319.408 56.7215C321.342 53.1237 324.049 50.3562 327.53 48.4189C331.01 46.4816 335.015 45.513 339.546 45.513C343.91 45.513 347.805 46.4539 351.23 48.3359C354.711 50.2178 357.39 52.9023 359.269 56.3894C361.202 59.8212 362.169 63.7787 362.169 68.2621ZM350.153 64.9411C350.098 61.9522 349.02 59.5721 346.921 57.8008C344.822 55.9743 342.253 55.061 339.214 55.061C336.341 55.061 333.911 55.9466 331.922 57.7178C329.988 59.4337 328.8 61.8415 328.358 64.9411H350.153Z"
        fill="#162521"
      />
      <path
        d="M389.449 45.596C394.918 45.596 399.338 47.3396 402.708 50.8267C406.078 54.2584 407.763 59.0739 407.763 65.2732V92.2567H396.161V66.8507C396.161 63.1975 395.25 60.4023 393.427 58.4651C391.603 56.4724 389.117 55.4761 385.968 55.4761C382.764 55.4761 380.223 56.4724 378.344 58.4651C376.521 60.4023 375.61 63.1975 375.61 66.8507V92.2567H364.008V46.2602H375.61V51.989C377.156 49.9964 379.118 48.4466 381.493 47.3396C383.924 46.1772 386.576 45.596 389.449 45.596Z"
        fill="#162521"
      />
      <path
        d="M425.674 55.8082V78.0592C425.674 79.609 426.033 80.7437 426.751 81.4633C427.525 82.1275 428.795 82.4596 430.563 82.4596H435.95V92.2567H428.657C418.879 92.2567 413.989 87.4965 413.989 77.9762V55.8082H408.52V46.2602H413.989V34.8856H425.674V46.2602H435.95V55.8082H425.674Z"
        fill="#162521"
      />
      <path
        d="M458.051 93.0039C453.631 93.0039 449.653 92.0353 446.117 90.098C442.582 88.1054 439.792 85.3102 437.748 81.7124C435.759 78.1146 434.764 73.9633 434.764 69.2585C434.764 64.5536 435.786 60.4023 437.83 56.8045C439.93 53.2067 442.775 50.4392 446.366 48.5019C449.957 46.5093 453.962 45.513 458.382 45.513C462.802 45.513 466.807 46.5093 470.398 48.5019C473.989 50.4392 476.807 53.2067 478.851 56.8045C480.95 60.4023 482 64.5536 482 69.2585C482 73.9633 480.923 78.1146 478.768 81.7124C476.669 85.3102 473.796 88.1054 470.15 90.098C466.559 92.0353 462.526 93.0039 458.051 93.0039ZM458.051 82.8747C460.15 82.8747 462.111 82.3766 463.934 81.3803C465.813 80.3286 467.304 78.7788 468.409 76.7308C469.514 74.6828 470.067 72.192 470.067 69.2585C470.067 64.8857 468.907 61.537 466.586 59.2123C464.321 56.8322 461.531 55.6422 458.216 55.6422C454.902 55.6422 452.112 56.8322 449.847 59.2123C447.637 61.537 446.532 64.8857 446.532 69.2585C446.532 73.6312 447.609 77.0076 449.764 79.3876C451.974 81.7124 454.736 82.8747 458.051 82.8747Z"
        fill="#162521"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M82.8697 63.6533C82.8697 74.3526 74.2126 83.0261 63.5335 83.0261C52.8543 83.0261 44.1972 74.3526 44.1972 63.6533C44.1972 52.9541 52.8543 44.2806 63.5335 44.2806C74.2126 44.2806 82.8697 52.9541 82.8697 63.6533ZM63.5335 70.5722C67.3474 70.5722 70.4393 67.4745 70.4393 63.6533C70.4393 59.8322 67.3474 56.7345 63.5335 56.7345C59.7195 56.7345 56.6276 59.8322 56.6276 63.6533C56.6276 67.4745 59.7195 70.5722 63.5335 70.5722Z"
        fill="#FF8000"
      />
    </svg>
  );
};
